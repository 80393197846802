.container  {
    position: absolute;
   
 
    border-width: 0px;
    margin-top: 1rem;
    padding: 1rem;
    border: 0px solid ;
    background-color: rgba(41, 40, 39, 0);
    
}

.outerDiv {
    max-height: fit-content;
}

.network  {
    position: relative;
    border-width: 0px;
    margin-top: 4rem;
    border: 0px solid ;
    background-color: rgba(41, 40, 39, 0);
    
}